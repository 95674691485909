import { EntityWithPermissions } from "../types/entity";
import { Organisation, Permission } from "../types/organisation";

export function hasPermission(permission: Permission, selection?: Organisation | EntityWithPermissions): boolean {
  if (selection === undefined) return false;
  return (selection.permissions as string[]).includes(permission);
}

export function hasOneOfPermissions(
  permissions: Permission[],
  selection?: Organisation | EntityWithPermissions,
): boolean {
  if (selection === undefined) return false;
  return permissions.some(p => hasPermission(p, selection));
}
