import { AnalyticsBrowser } from "@segment/analytics-next";
import { useFeature } from "flagged";
import { FC, createContext, useContext, useEffect } from "react";

import { useProfile } from "../components/hooks/useProfile";

import { useAuthentication } from "./AuthenticationContext";
import { useDashboardContext } from "./DashboardContext";

interface ContextInterface {
  logScreen: (screenName: string) => void;
  logEvent: (name: string, metadata?: unknown) => void;
}

const JournyContext = createContext<ContextInterface>({
  logScreen: () => {},
  logEvent: () => {},
});

export const journy = AnalyticsBrowser.load(
  {
    writeKey: "01JMCNKBYWG27F8FYC2M97W2Z5",
    cdnURL: "https://journy.monitr.be/frontend",
  },
  {
    integrations: {
      "Segment.io": {
        apiHost: "journy.monitr.be/frontend/v1",
      },
    },
  },
);

export const JournyProvider: FC = ({ children }) => {
  const { selectedOrganisation, organisations } = useDashboardContext();
  const { decodedToken } = useAuthentication();
  const organisation = organisations?.find(o => o.id === selectedOrganisation);
  const enableJourny = useFeature("enableJourny");
  const { user } = useProfile();

  function logScreen(screenName: string) {
    if (enableJourny) journy.page(undefined, screenName, {}, { userId: decodedToken?.sub, groupId: organisation?.id });
  }

  function logEvent(name: string, metadata?: unknown) {
    if (enableJourny) journy.track(name, metadata ?? {}, { userId: decodedToken?.sub, groupId: organisation?.id });
  }

  useEffect(() => {
    if (enableJourny) {
      // Identify the user
      if (decodedToken && user)
        journy.identify(decodedToken.sub, {
          email: decodedToken.email,
          firstName: user.firstName,
          lastName: user.lastName,
        });

      // Identify the account (organisation)
      if (organisation?.id && organisation?.name)
        journy.group(organisation.id, { name: organisation.name }, { userId: decodedToken?.sub });
    }
  }, [decodedToken, enableJourny, organisation?.id, organisation?.name, user]);

  const defaultContext: ContextInterface = {
    logScreen,
    logEvent,
  };

  return <JournyContext.Provider value={defaultContext}>{children}</JournyContext.Provider>;
};

export function useJourny(): ContextInterface {
  return useContext(JournyContext);
}
