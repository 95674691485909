import axios from "axios";
import useAxios from "axios-hooks";
import { useCallback, useMemo } from "react";

import { assertUnreachable, objectsToMap } from "../../../../util/utils";
import { ReportingLine, ReportingLineResponse } from "../types";

interface Params {
  entityId?: string;
  manual?: boolean;
}

const useReportingLines = ({ entityId, manual }: Params) => {
  const [{ data: reportingLinesData, error, loading }, refetchReportingLines] = useAxios<ReportingLineResponse[]>(
    {
      url: `/entities/${entityId}/reporting-lines`,
    },
    { manual: entityId === undefined || manual },
  );

  const [reportingLines, reportingLinesById, leafReportingLines] = useMemo<
    [ReportingLine[] | undefined, Map<string, ReportingLine> | undefined, ReportingLine[]]
  >(() => {
    if (reportingLinesData === undefined) return [undefined, undefined, []];
    const repLines = reportingLinesData.map(rl => ({
      ...rl,
      parentId: rl.parent_id,
      cashFlowReportingLineId: rl.cash_flow_reporting_line_id,
    }));
    const repLinesById = objectsToMap<ReportingLine, ReportingLine["id"]>(repLines, "id");
    const parentReportingLines = new Set(repLines.map(rl => rl.parentId));
    const leafRepLines =
      repLines
        ?.filter(rl => !parentReportingLines.has(rl.id))
        .map(rl => ({ ...rl, parentId: rl.parent_id, cashFlowReportingLineId: rl.cash_flow_reporting_line_id })) ?? [];
    return [repLines, repLinesById, leafRepLines];
  }, [reportingLinesData]);

  const refetch = useCallback(async (): Promise<ReportingLine[]> => {
    const res = await refetchReportingLines();
    return res.data.map(rl => ({
      ...rl,
      parentId: rl.parent_id,
      cashFlowReportingLineId: rl.cash_flow_reporting_line_id,
    }));
  }, [refetchReportingLines]);

  async function put(reportingLines: ReportingLine[]): Promise<void> {
    const { plLines, bsLines } = reportingLines.reduce(
      (res, rl) => {
        switch (rl.report) {
          case "PL":
            return {
              ...res,
              plLines: [
                ...res.plLines,
                { ...rl, parent_id: rl.parentId, cash_flow_reporting_line_id: rl.cashFlowReportingLineId },
              ],
            };
          case "BS":
            return {
              ...res,
              bsLines: [
                ...res.bsLines,
                { ...rl, parent_id: rl.parentId, cash_flow_reporting_line_id: rl.cashFlowReportingLineId },
              ],
            };
          default:
            return assertUnreachable(rl.report);
        }
      },
      {
        plLines: new Array<ReportingLine>(),
        bsLines: new Array<ReportingLine>(),
      },
    );

    await Promise.all([
      axios.put(`/entities/${entityId}/reporting-lines/PL`, { reportingLines: plLines }),
      axios.put(`/entities/${entityId}/reporting-lines/BS`, { reportingLines: bsLines }),
    ]);
  }

  if (error !== null) throw error;

  return {
    reportingLines,
    leafReportingLines,
    reportingLinesById,
    loading,
    refetch,
    put,
  };
};

export default useReportingLines;
