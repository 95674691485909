import { Import } from "../components/hooks/useImports/types";

import { Entity } from "./entity";

export type IntegrationProviderId =
  | "csv"
  | "yuki"
  | "exact"
  | "exact:nl"
  | "afas"
  | "teamleader"
  | "hubspot"
  | "monitr"
  | "octopus"
  | "stripe"
  | "xero"
  | "odoo"
  | "twinfield"
  | "nmbrs"
  | "quickbooks"
  | "moneybird"
  | "finteqx";

export enum IntegrationType {
  Bookkeeping = "bookkeeping",
  CRM = "crm",
  Subscription = "subscription",
  Project = "project",
  HistoricOutstandingItem = "historic-outstanding-item",
  PersonnelCosts = "personnel-costs",
  AnnualAccountsFiling = "annual-accounts-filing",
}

export interface IntegrationProvider {
  id: IntegrationProviderId;
  type: IntegrationType;
  name: string;
  author: string;
  icon: string;
  description: string;
}

export enum CRMBudgetingMethod {
  perProduct = "perProduct",
  acrossProducts = "acrossProducts",
}

interface IntegrationSettings {
  invoiceDateId: string;
}

export interface Integration {
  id: string;
  integrationProviderId: IntegrationProviderId;
  integrationProvider: IntegrationProvider;
  integrationType: IntegrationType;
  organisationId: string;
  entityId?: string;
  entity: Entity | null;
  entities?: Entity[];
  settings: Partial<IntegrationSettings>;
  import?: Import;
  created_at: string;
}

export interface IntegrationLanguage {
  id: number;
  name: string;
  active: boolean;
  code: string;
  isoCode: string;
}
