/**
 * TODO: Pull out validation API check from this context,
 * too heavy and only used on a few views
 */

import { Spinner, SpinnerSize } from "@fluentui/react";
import { AxiosPromise } from "axios";
import useAxios from "axios-hooks";
import moment from "moment";
import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-use";

import { Scenario } from "../components/hooks/useForecasts/types";
import { Aggregation, DeltaComparison } from "../components/hooks/useReports/types";
import {
  CanFetchResult,
  ValidationResult,
  ValidationsResponse,
} from "../components/pages/Dashboard/Definitions/Validations/types";
import { SelectedHeaders } from "../components/pages/Dashboard/Organisations/common/ImportCSV/steps/types";
import { Cardinality, ReportPeriod, SetState } from "../types/common";
import { EntitiesResponse, Entity, EntityOnboardingStatus, EntityWithPermissions } from "../types/entity";
import { IntegrationType } from "../types/integration";
import { hasPermission } from "../util/permissions";

import { useDashboardContext } from "./DashboardContext";
import { LocalEntitySetting, LocalEntitySettings } from "./EntityContext.types";

interface ContextInterface {
  selectedEntity?: Entity["id"];
  setSelectedEntity: React.Dispatch<React.SetStateAction<string | undefined>>;
  entities: (Entity & EntityWithPermissions)[];
  setEntities: SetState<(Entity & EntityWithPermissions)[]>;
  deletedEntities: (Entity & EntityWithPermissions)[];
  refetchEntities: () => AxiosPromise<Entity[]>;
  refetchPermissions: () => AxiosPromise<EntitiesResponse<EntityWithPermissions>>;
  loading: boolean;
  initialLoading: boolean;
  validations?: ValidationsResponse;
  loadingValidations?: boolean;
  validationResult: ValidationResult;
  filingsAreValid: boolean;
  cardinality: Cardinality;
  setCardinality: (cardinality: Cardinality) => void;
  dashboardComparison: DeltaComparison;
  setDashboardComparison: (dashboardComparison: DeltaComparison) => void;
  reportingPeriod?: ReportPeriod;
  reportingPeriodChanged: boolean;
  setReportingPeriod: (reportPeriod?: ReportPeriod) => void;
  reportingAggregation: Aggregation;
  setReportingAggregation: (reportAggregation: Aggregation) => void;
  selectedScenarioId?: Scenario["id"];
  setSelectedScenarioId: (id?: Scenario["id"]) => void;
  selectedComparisonScenarioId?: Scenario["id"];
  setSelectedComparisonScenarioId: (id?: Scenario["id"]) => void;
  importCsvSelectedHeaders?: SelectedHeaders;
  setImportCsvSelectedHeaders: (importCsvSelectedHeaders: SelectedHeaders) => void;
  setScenarioIdsToCompare: (baselineScenarioId?: Scenario["id"], comparisonScenarioId?: Scenario["id"]) => void;
}

const defaultLocalEntitySettings: LocalEntitySetting = {
  cardinality: { type: "value", value: 1000 },
  dashboardComparison: "budget",
};

const EntityContext = createContext<ContextInterface>({
  setSelectedEntity: () => {
    return;
  },
  entities: [],
  setEntities: () => {},
  deletedEntities: [],
  refetchEntities: () => new Promise(resolve => resolve),
  refetchPermissions: () => new Promise(resolve => resolve),
  loading: true,
  initialLoading: true,
  loadingValidations: true,
  validationResult: { type: "loading" },
  filingsAreValid: true,
  cardinality: defaultLocalEntitySettings.cardinality,
  setCardinality: () => {},
  dashboardComparison: defaultLocalEntitySettings.dashboardComparison,
  setDashboardComparison: () => {},
  reportingPeriodChanged: false,
  setReportingPeriod: () => {},
  reportingAggregation: {
    type: "period",
    value: "month",
  },
  setReportingAggregation: () => {},
  setSelectedScenarioId: (_?: Scenario["id"]) => {},
  setSelectedComparisonScenarioId: (_?: Scenario["id"]) => {},
  setImportCsvSelectedHeaders: () => {},
  setScenarioIdsToCompare: () => {},
});

interface Props {
  showLoading?: boolean;
}

export const EntityProvider: FC<Props> = ({ children, showLoading = true }) => {
  const storedSelectedEntity = window.localStorage.getItem("selectedEntity") || undefined;
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedOrganisation } = useDashboardContext();
  const [entities, setEntities] = useState<(Entity & EntityWithPermissions)[]>([]);
  const [localSettings, setLocalSettings] = useLocalStorage<LocalEntitySettings>("settings:entities");
  const [deletedEntities, setDeletedEntities] = useState<(Entity & EntityWithPermissions)[]>([]);
  const [selectedEntity, setSelectedEntity] = useState(storedSelectedEntity);
  const [initialLoading, setInitialLoading] = useState(true);
  const [{ data, loading, error }, refetchEntities] = useAxios<Entity[]>(
    {
      url: `/organisations/${selectedOrganisation}/entities`,
    },
    {
      manual: !selectedOrganisation,
    },
  );

  const [{ data: permissionsData, loading: permissionsLoading, error: permissionsError }, refetchPermissions] =
    useAxios<EntitiesResponse<EntityWithPermissions>>(
      {
        url: `/users/entities`,
        params: { orgId: selectedOrganisation },
      },
      {
        manual: !selectedOrganisation,
      },
    );

  const entity = useMemo(() => entities?.find(e => e.id === selectedEntity), [entities, selectedEntity]);

  const getLocalSettings = useCallback(
    (entityId?: string): LocalEntitySetting => {
      if (entityId === undefined || localSettings === undefined || localSettings[entityId] === undefined)
        return defaultLocalEntitySettings;
      return localSettings[entityId];
    },
    [localSettings],
  );

  const storeLocalSettings = useCallback(
    (entityId: string, value: Partial<LocalEntitySetting>) => {
      setLocalSettings({
        ...localSettings,
        [entityId]: {
          ...(localSettings ?? {})[entityId],
          ...value,
        },
      });
    },
    [localSettings, setLocalSettings],
  );

  const cardinality = useMemo(() => {
    const localSettings = getLocalSettings(selectedEntity);
    // We check for typeof number because we changed the type, but the old one can still be in people's cache
    if (localSettings.cardinality === undefined || typeof localSettings.cardinality === "number")
      return defaultLocalEntitySettings.cardinality;
    return localSettings.cardinality;
  }, [getLocalSettings, selectedEntity]);

  const setCardinality = useCallback(
    (newCardinality: Cardinality) => {
      if (selectedEntity === undefined) return;
      storeLocalSettings(selectedEntity, { cardinality: newCardinality });
    },
    [selectedEntity, storeLocalSettings],
  );

  const dashboardComparison = useMemo(() => {
    const localSettings = getLocalSettings(selectedEntity);
    if (localSettings.dashboardComparison === undefined) return defaultLocalEntitySettings.dashboardComparison;
    return localSettings.dashboardComparison;
  }, [getLocalSettings, selectedEntity]);

  const setDashboardComparison = useCallback(
    (dashboardComparison: DeltaComparison) => {
      if (selectedEntity === undefined) return;
      storeLocalSettings(selectedEntity, { dashboardComparison });
    },
    [selectedEntity, storeLocalSettings],
  );

  const reportingPeriod = useMemo(() => {
    const localSettings = getLocalSettings(selectedEntity);
    if (localSettings?.reportingPeriod === undefined) {
      // Fallback to period from entity settings if no period is found in local storage
      const entity = entities.find(e => e.id === selectedEntity);
      if (entity === undefined) return;
      return {
        start: entity.ytd_start,
        lda: entity.last_date_actuals,
        end: entity.ytg_end,
      };
    }
    return localSettings.reportingPeriod;
  }, [entities, getLocalSettings, selectedEntity]);

  const reportingPeriodChanged = useMemo(() => {
    const localSettings = getLocalSettings(selectedEntity);
    return localSettings?.reportingPeriod !== undefined;
  }, [getLocalSettings, selectedEntity]);

  const setReportingPeriod = useCallback(
    (newReportingPeriod?: ReportPeriod) => {
      if (selectedEntity === undefined) return;
      storeLocalSettings(selectedEntity, { reportingPeriod: newReportingPeriod });
    },
    [selectedEntity, storeLocalSettings],
  );

  const reportingAggregation: Aggregation = useMemo(() => {
    const localSettings = getLocalSettings(selectedEntity);
    if (localSettings.reportingAggregation === undefined) {
      // Fallback to month if no aggregation is found in local storage
      return { type: "period", value: "month" };
    }
    return localSettings.reportingAggregation;
  }, [getLocalSettings, selectedEntity]);

  const setReportingAggregation = useCallback(
    (newReportingAggregation?: Aggregation) => {
      if (selectedEntity === undefined) return;
      storeLocalSettings(selectedEntity, { reportingAggregation: newReportingAggregation });
    },
    [selectedEntity, storeLocalSettings],
  );

  const selectedScenarioId = useMemo(() => {
    return getLocalSettings(selectedEntity)?.scenarioId;
  }, [getLocalSettings, selectedEntity]);

  const setSelectedScenarioId = useCallback(
    (newScenarioId?: string) => {
      if (selectedEntity === undefined) return;
      storeLocalSettings(selectedEntity, { scenarioId: newScenarioId });
    },
    [selectedEntity, storeLocalSettings],
  );

  const selectedComparisonScenarioId = useMemo(
    () => getLocalSettings(selectedEntity)?.comparisonScenarioId,
    [getLocalSettings, selectedEntity],
  );

  const setSelectedComparisonScenarioId = useCallback(
    (newScenarioId?: string) => {
      if (selectedEntity === undefined) return;
      storeLocalSettings(selectedEntity, { comparisonScenarioId: newScenarioId });
    },
    [selectedEntity, storeLocalSettings],
  );

  const setScenarioIdsToCompare = useCallback(
    (baselineScenarioId?: string, comparisonScenarioId?: string) => {
      if (selectedEntity === undefined) return;
      storeLocalSettings(selectedEntity, { scenarioId: baselineScenarioId, comparisonScenarioId });
    },
    [selectedEntity, storeLocalSettings],
  );

  const importCsvSelectedHeaders = useMemo(
    () => getLocalSettings(selectedEntity)?.importCsvSelectedHeaders,
    [getLocalSettings, selectedEntity],
  );

  const setImportCsvSelectedHeaders = useCallback(
    (newImportCsvSelectedHeaders: SelectedHeaders, specifiedEntity?: string) => {
      if (selectedEntity === undefined) return;
      storeLocalSettings(specifiedEntity ?? selectedEntity, { importCsvSelectedHeaders: newImportCsvSelectedHeaders });
    },
    [selectedEntity, storeLocalSettings],
  );

  // Might cause some unwanted layout flashes due to re-renders.
  // This is needed to refresh the state properly after the selected organisation has changed
  useEffect(() => {
    if (typeof selectedOrganisation === "undefined") return;
    const storedSelectedOrganisation = window.localStorage.getItem("selectedOrganisation") || undefined;
    if (storedSelectedOrganisation === selectedOrganisation) return;
    setSelectedEntity(undefined);
  }, [selectedOrganisation]);

  const qs = useMemo(() => {
    if (!entity) return;

    return {
      from: entity?.ytd_start || moment().startOf("year").format("YYYY-MM-DD"),
      to: entity?.last_date_actuals || moment().endOf("year").format("YYYY-MM-DD"),
    };
  }, [entity]);

  const canFetchValidations = useMemo((): CanFetchResult => {
    if (entity === undefined) return { canFetch: false, reason: "noEntity" };
    if (entity.last_date_actuals === null) return { canFetch: false, reason: "noLastDateActuals" };
    return { canFetch: true };
  }, [entity]);

  const [{ data: dataValidation, loading: loadingValidations, error: validationsError }] =
    useAxios<ValidationsResponse>(
      {
        url: `/entities/${selectedEntity}/validations/status`,
        params: qs,
      },
      {
        manual: !canFetchValidations.canFetch,
      },
    );

  useEffect(() => {
    if (data === undefined || permissionsData === undefined) return;

    // If we have entities with undefined permissions, keep waiting for permissions
    // This is to prevent the brief "unauthorized" flashing issue due to async API requests
    const hasNotYetLoadedPermissions = data.reduce((prev, entity) => {
      const permissions = permissionsData.entities.find(ewp => ewp.id === entity.id)?.permissions;
      if (permissions === undefined) return true;

      return prev;
    }, false);
    if (hasNotYetLoadedPermissions) return;

    // Match entities with permissions
    const entitiesWithPermissions = data.map(e => ({
      ...e,
      permissions: permissionsData.entities.find(ewp => ewp.id === e.id)?.permissions ?? [],
    }));

    const { activeEntities, deletedEntities } = entitiesWithPermissions.reduce(
      (res, e) => {
        if (e.status === "deleted") return { ...res, deletedEntities: [...res.deletedEntities, e] };
        return { ...res, activeEntities: [...res.activeEntities, e] };
      },
      {
        activeEntities: new Array<Entity & EntityWithPermissions>(),
        deletedEntities: new Array<Entity & EntityWithPermissions>(),
      },
    );

    if (!selectedEntity && activeEntities.length > 0) {
      setSelectedEntity(activeEntities[0].id);
    }
    // Make sure the stored entity is an entity that your have access to
    else if (activeEntities.length > 0 && activeEntities.findIndex(e => e.id === selectedEntity) === -1) {
      setSelectedEntity(activeEntities[0].id);
    } else if (activeEntities.length === 0) {
      setSelectedEntity("");
    }

    setInitialLoading(false);
    setEntities(activeEntities);
    setDeletedEntities(deletedEntities);
  }, [data, permissionsData, selectedEntity]);

  // Persist selection to local storage
  useEffect(() => {
    if (!selectedEntity) return window.localStorage.removeItem("selectedEntity");
    return window.localStorage.setItem("selectedEntity", selectedEntity);
  }, [selectedEntity]);

  const shouldCheckOnboardingNavigation = useMemo(
    () => !location.pathname.includes("/dashboard/organisations/billing"),
    [location],
  );

  // Check if we should navigate based on onboarding progress
  useEffect(() => {
    if (!shouldCheckOnboardingNavigation) return;
    if (entity === undefined) return;
    if (entity.onboarding_status === EntityOnboardingStatus.Done) return;
    if (entity.organisation_id !== selectedOrganisation) return;
    return navigate(`/onboarding/entity?entityId=${entity.id}`, { replace: true });
  }, [navigate, entity, selectedOrganisation, shouldCheckOnboardingNavigation]);

  if (error) {
    if (error.response?.status !== 401) throw error;
  }

  if (permissionsError) {
    if (permissionsError.response?.status !== 401) throw error;
  }

  const checkValidation = (dataVal: ValidationsResponse): boolean => {
    const { checkBS, checkCFvBS, unmappedProducts, budgetAccountsWithoutVatRates } = dataVal;
    const BSIsOk = checkBS.every(val => val.bool);
    const CFvBSIsOK = checkCFvBS.every(val => val.bool);
    const unmappedProductsIsOk = unmappedProducts === null || unmappedProducts.length === 0;
    const vatRatesOk = budgetAccountsWithoutVatRates === null || budgetAccountsWithoutVatRates.length === 0;
    return BSIsOk && CFvBSIsOK && unmappedProductsIsOk && vatRatesOk;
  };

  const validationResult = useMemo((): ValidationResult => {
    if (!canFetchValidations.canFetch) {
      return { type: "error", reason: canFetchValidations.reason };
    }
    if (loadingValidations) return { type: "loading" };
    if (validationsError) {
      return { type: "error", reason: "requestFailed", error: validationsError };
    }
    if (dataValidation === undefined) {
      return { type: "error", reason: "requestFailed", error: new Error("validations undefined") };
    }
    return { type: "done", isValid: checkValidation(dataValidation) };
  }, [canFetchValidations, dataValidation, loadingValidations, validationsError]);

  const canFetchAnnualAccountsFilingValidation = useMemo((): boolean => {
    if (entity === undefined) return false;

    const hasAnnualAccountsFiling =
      entity.integrations?.some(i => i.integrationType === IntegrationType.AnnualAccountsFiling) ?? false;
    return hasAnnualAccountsFiling && hasPermission("entity.core:read", entity);
  }, [entity]);

  const [{ data: annualAccountsFilingValidation }] = useAxios<{ isValid: boolean }>(
    {
      url: `/entities/${selectedEntity}/annual-accounts-filing/general-ledger-changes/validation`,
    },
    {
      manual: !canFetchAnnualAccountsFilingValidation,
    },
  );

  const defaultContext: ContextInterface = {
    selectedEntity,
    setSelectedEntity,
    entities,
    setEntities,
    deletedEntities,
    refetchEntities,
    refetchPermissions,
    loading: loading || permissionsLoading,
    initialLoading,
    validations: dataValidation,
    loadingValidations: loadingValidations,
    validationResult,
    filingsAreValid: annualAccountsFilingValidation?.isValid ?? true,
    cardinality,
    setCardinality,
    dashboardComparison,
    setDashboardComparison,
    reportingPeriod,
    reportingPeriodChanged,
    setReportingPeriod,
    reportingAggregation,
    setReportingAggregation,
    selectedScenarioId,
    setSelectedScenarioId,
    selectedComparisonScenarioId,
    setSelectedComparisonScenarioId,
    importCsvSelectedHeaders,
    setImportCsvSelectedHeaders,
    setScenarioIdsToCompare,
  };

  if (showLoading && (loading || permissionsLoading))
    return <Spinner style={{ height: "100%" }} size={SpinnerSize.large} />;

  return <EntityContext.Provider value={defaultContext}>{children}</EntityContext.Provider>;
};

export function useEntityContext(): ContextInterface {
  return useContext(EntityContext);
}
